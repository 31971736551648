<script>
import Vue from "vue";
import BaseVue from "@frontend/Base.vue";
import Gen from "../helper/Gen";

export default {
  extends: BaseVue,
  data() {
    return {
      ringkasan: {
        footer: {
          desc: "",
        },
        header: {
          desc: "",
        },
        jenis: [],
      },
      pembelajaran: {
        deskripsi: {
          desc: "",
        },
        materi: [],
      },
      budayaSekolah: {
        deskripsi: {
          desc: "",
        },
        materi: [],
      },
      ekstrakurikuler: {
        deskripsi: {
          desc: "",
        },
        materi: [],
      },
      heroImage: "",
    };
  },
  computed: {},
  async mounted() {
    this.getContent();
    this.$set(this.$root, "page", this);
  },
  methods: {
    getContent() {
      Gen.apirest("/curriculum", {}, (err, resp) => {
        if (err) console.log("Kurikulum " + err);
        this.ringkasan = resp.ringkasan;
        this.pembelajaran = resp.pembelajaran;
        this.budayaSekolah = resp.budayaSekolah;
        this.ekstrakurikuler = resp.ekstrakurikuler;
        this.heroImage = resp.heroImage;
      });
    },
  },
  watch: {},
};
</script>
<template>
  <div>
    <section
      v-if="!this.$root.isMobile"
      id="page-title"
      :style="`--title-img:url('${uploader(heroImage.img)}')`"
    >
      <div class="container">
        <span class="d-none position-absolute"> {{ heroImage.alt_img }} </span>
        <div class="vertical-middle">
          <div class="row">
            <div class="col-lg-5 col-md-6">
              <h2 itemprop="alternativeHeadline" class="page__title">Kurikulum</h2>
              <ol itemscope itemtype="https://schema.org/BreadcrumbList" class="breadcrumb">
                <li 
                  itemprop="itemListElement"
                  itemscope
                  itemtype="https://schema.org/ListItem"
                  class="breadcrumb-item"
                >
                  <router-link :to="{ name: 'Index' }">Home</router-link>
                </li>
                <li 
                  itemprop="itemListElement"
                  itemscope
                  itemtype="https://schema.org/ListItem"
                  class="breadcrumb-item"
                >
                  Akademik
                </li>
                <li 
                  itemprop="itemListElement"
                  itemscope
                  itemtype="https://schema.org/ListItem"
                  class="breadcrumb-item active" aria-current="page"
                >
                  Kurikulum
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
      v-else
      id="page-title"
      :style="`--title-img:url('${uploader(heroImage.img_mobile)}')`"
    >
      <div class="container">
        <span class="d-none position-absolute"> {{ heroImage.alt_img_mobile }} </span>
        <div class="vertical-middle">
          <div class="row">
            <div class="col-lg-5 col-md-6">
              <h2 itemprop="alternativeHeadline" class="page__title">Kurikulum</h2>
              <ol itemscope itemtype="https://schema.org/BreadcrumbList" class="breadcrumb">
                <li 
                  itemprop="itemListElement"
                  itemscope
                  itemtype="https://schema.org/ListItem"
                  class="breadcrumb-item"
                >
                  <router-link :to="{ name: 'Index' }">Home</router-link>
                </li>
                <li 
                  itemprop="itemListElement"
                  itemscope
                  itemtype="https://schema.org/ListItem"
                  class="breadcrumb-item"
                >
                  Akademik
                </li>
                <li 
                  itemprop="itemListElement"
                  itemscope
                  itemtype="https://schema.org/ListItem"
                  class="breadcrumb-item active" aria-current="page"
                >
                  Kurikulum
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="content">
      <div class="content-wrap">
        <section class="section py-64">
          <div class="container">
            <div class="heading-block center border-0">
              <h2 itemprop="alternativeHeadline">Kurikulum SD Islam Al Hidayah</h2>
            </div>
            <div class="row justify-content-center">
              <div class="col-lg-8 col-md-10">
                <div
                  itemprop="description"
                  class="curriculum-desc"
                  v-html="ringkasan.header.desc"
                ></div>
              </div>
              <div class="col-12">
                <div class="curriculum-values">
                  <div class="row col-mb-48 justify-content-center">
                    <div
                      class="col-lg-4 col-md-6"
                      v-for="datas in this.ringkasan.jenis"
                      :key="datas.acg_id"
                    >
                      <div class="card-elem">
                        <div class="values flex-column">
                          <div :class="['value-circle mr-0', datas.acg_bg]">
                            <div class="value-circle__content">
                              <img itemprop="image" :src="uploader(datas.acg_img)" :alt="datas.acg_alt_img" :title="datas.acg_alt_img" />
                            </div>
                          </div>
                          <div class="value-content w-100 text-center mt-3">
                            <h3 itemprop="name" class="value-content__title">
                              {{ datas.acg_title }}
                            </h3>
                            <p itemprop="description" class="value-content__desc">
                              {{ datas.acg_desc }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-8 col-md-10">
                <div
                  itemprop="description"
                  class="curriculum-desc"
                  v-html="ringkasan.footer.desc"
                ></div>
              </div>
            </div>
          </div>
        </section>
        <section class="section py-64">
          <div class="container">
            <div class="heading-block center border-0">
              <h2 itemprop="alternativeHeadline">Kegiatan Pembelajaran</h2>
            </div>
            <div class="row justify-content-center">
              <div class="col-lg-8 col-md-10">
                <div
                  itemprop="description"
                  class="curriculum-desc"
                  v-html="pembelajaran.deskripsi.desc"
                ></div>
              </div>
              <div class="col-12">
                <div class="curriculum-values mb-0">
                  <div class="row justify-content-center col-mb-48">
                    <div
                      class="col-md-6 col-lg-4"
                      v-for="data in pembelajaran.materi"
                      :key="data.acg_id"
                    >
                      <div class="curriculum-media">
                        <img
                          itemprop="photo"
                          :src="uploader(data.acg_img)"
                          :alt="data.acg_alt_img"
                          :title="data.acg_alt_img"
                          class="curriculum-media__img"
                        />
                        <h3 itemprop="name" class="curriculum-media__title">
                          {{ data.acg_title }}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="section pt-50m pb-20m">
          <div class="container">
            <div class="heading-block center border-0">
              <h2 itemprop="alternativeHeadline">Budaya Sekolah</h2>
            </div>
            <div class="row justify-content-center">
              <div class="col-lg-8 col-md-10">
                <div
                  itemprop="description"
                  class="curriculum-desc"
                  v-html="budayaSekolah.deskripsi.desc"
                ></div>
              </div>
              <div class="col-12">
                <div class="curriculum-values">
                  <div class="row justify-content-center col-mb-48">
                    <div
                      class="col-md-6 col-lg-4"
                      v-for="data in budayaSekolah.materi"
                      :key="data.acg_id"
                    >
                      <div class="card-elem">
                        <div class="count">
                          <div
                            :class="[
                              'count-circle count-circle--sm',
                              data.acg_bg,
                            ]"
                          >
                            <img
                              itemprop="image"
                              :src="uploader(data.acg_img)"
                              :alt="data.acg_alt_img"
                              :title="data.acg_alt_img"
                              class="count-circle__img"
                            />
                          </div>
                          <div class="count-content count-content--sm">
                            <h3 itemprop="name" class="count-content__number">
                              {{ data.acg_title }}
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="section pt-50m pb-50m">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-6 col-md-8">
                <div class="heading-block center border-0">
                  <h2 itemprop="alternativeHeadline">Kegiatan Pengembangan Diri dan Ekstrakurikuler</h2>
                </div>
              </div>
              <div class="col-lg-8 col-md-10">
                <div
                  itemprop="description"
                  class="curriculum-desc"
                  v-html="ekstrakurikuler.deskripsi.desc"
                ></div>
              </div>
              <div class="col-12">
                <div class="curriculum-values">
                  <div class="row justify-content-center gutter-24">
                    <div
                      class="col-lg-3 col-md-4"
                      v-for="data in ekstrakurikuler.materi"
                      :key="data.acg_id"
                    >
                      <div class="curriculum-media curriculum-media--sm">
                        <img
                          itemprop="photo"
                          :src="uploader(data.acg_img)"
                          :alt="data.acg_alt_img"
                          :title="data.acg_alt_img"
                          class="curriculum-media__img"
                        />
                        <h3 itemprop="name" class="curriculum-media__title">
                          {{ data.acg_title }}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
  </div>
</template>
